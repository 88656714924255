@import 'components/index';
@import 'pages/index';

html {
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}
body {
  overscroll-behavior: none;
  overflow: hidden;
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Avenir', -apple-system, Hiragino Sans, 'ヒラギノ角ゴ ProN W3', Meiryo UI, Meiryo,
    'メイリオ', 'ＭＳ Ｐゴシック', sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 0.19px;
  height: 100%;
}

button {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 14px;
  font-weight: 600;
}

img,
svg {
  width: 100%;
  height: auto;
  display: block;
}

#app {
  overscroll-behavior: none;
  overflow: hidden;
  height: 100%;
  #react-app {
    overscroll-behavior: none;
    overflow: hidden;
    height: 100%;
    & > .wrapper {
      overscroll-behavior: none;
      overflow: hidden;
      height: 100%;
      .react-swipe-container {
        overscroll-behavior: none;
        height: 100%;

        & > div {
          height: 100%;
        }
        .container {
          overscroll-behavior: none;
          overflow: hidden;
          height: 100%;
          background: $bgcolor;
          &.shared {
            background: $sharedbgcolor;
          }

          .timeline-wrapper {
            overscroll-behavior: none;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            height: 100%;
            opacity: 0;
            transition: opacity 100ms ease-out;
            &.loaded {
              opacity: 1;
            }
          }
        }
      }
    }

    & > .projects {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      height: 100%;
    }
  }
}

.thinking {
  padding: 0 0 30px;
  &__inner {
    width: 65px;
    padding: 8px 30px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    border-radius: 10px;
  }
}
