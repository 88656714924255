@import '../../../../sass/_var.scss';
.header {
  height: 60px;
  padding: 10px 20px 0;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  background: $bgcolor;

  &.shared {
    background: #f7f6f5;
  }

  &__inner {
    display: flex;
    height: 40px;
    align-items: center;
    text-align: center;
    padding: 0;
  }

  &__left {
    flex: 0 0 8vw;
    text-align: left;
    white-space: nowrap;
  }

  &__center {
    flex: 1;
    text-align: left;
    position: relative;
    height: 40px;

    label {
      font-size: 16px;
      font-weight: bold;
      display: block;
      //position: absolute;
      //top: 0;
      //left: 0;
      height: 40px;
      line-height: 40px;
      transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
      //width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      transform: translateY(0);
      opacity: 1;

      .scroll-top & {
        transform: translateY(-10px);
        opacity: 0;
      }
    }
  }

  &__right {
    flex: 0 0 16vw;
    justify-content: space-between;
    text-align: right;
    white-space: nowrap;
    font-size: 15px;
    display: flex;
  }

  &__btn-note {
    width: 30px;
    height: auto;
    display: block;
    padding: 0px;
    box-sizing: content-box;

    svg,
    img {
      width: 30px;
      height: auto;
      vertical-align: middle;
    }

    &--active {
      svg {
        .el {
          fill: $mybubbletxtcolor;
        }
      }
    }
  }

  &__btn {
    width: 20px;
    height: auto;
    display: block;
    padding: 10px;
    box-sizing: content-box;

    img {
      width: 20px;
      height: auto;
      vertical-align: middle;
    }
  }
}
