.footer {
  background: white;
  border-top: 1px solid #f0eff1;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;

  &.quick {
    max-height: 88px;
  }

  &__inner {
    display: flex;
    align-items: flex-end;
    padding: 8px 15px;
  }

  &__left {
    flex: 0;
    white-space: nowrap;
    padding-right: 15px;
  }

  &__center {
    flex: 1;

    input[type='text'] {
      font-size: 19px;
      transform: scale(0.8);
      transform-origin: left;
      width: 100%;
      min-height: 34px;
      line-height: 18px;
      display: block;
      border-radius: 4px;
      padding: 5px 8px;
      &::-webkit-input-placeholder {
        color: #ababab;
      }
      outline: 0;
    }
  }

  &__right {
    flex: 0;
    white-space: nowrap;
    padding-left: 15px;

    button {
      color: $maincolor;
      display: block;
      min-height: 34px;
      border-radius: 4px;
      text-align: center;
      line-height: 28px;

      &.disabled {
        color: $subtxtcolor;
      }

      i {
        color: white;
      }
      svg {
        width: 11px;

        polygon {
          fill: white;
        }
      }
    }
  }

  &__menu {
    max-height: 0;
    overflow: hidden;
    transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);

    &.is-active {
      max-height: 100vh;
    }

    li {
      display: block;
      border-bottom: 1px solid #dadada;
      &:last-child {
        border-bottom: 0;
      }

      a {
        padding: 0 15px;
        min-height: 44px;
        line-height: 44px;
        text-decoration: none;
        color: inherit;
        display: block;
      }
    }
  }
}
