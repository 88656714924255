.projects {
  width: 100%;
  background: white;
  overflow-x: hidden;

  &__header {
    padding: 10px 20px;
    min-height: 1em;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background: white;
  }
  &__header-left {
    flex: 0 0 70px;
    button {
      width: 15px;
      padding: 10px;
      box-sizing: content-box;
    }
  }
  &__header-center {
    flex: 1;
    text-align: center;
  }
  &__header-right {
    flex: 0 0 70px;
    text-align: right;

    button {
      padding: 10px;
    }
  }
  &__content {
    padding: 100px 30px 10px;
    min-height: 100vh;
    transition: transform 300ms cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateX(-45px);

    &--editable {
      transform: translateX(0);
    }
  }
  &__section {
    margin: 15px 0;
  }
  &__heading {
    margin: 30px 0 5px;
    color: $subtxtcolor;
  }
  &__btn {
    color: $txtcolor;
    font-weight: bold;

    &--editable {
      color: $maincolor;
    }
  }

  &__add {
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 15px;
    span {
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: $maincolor;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
        display: block;
      }
    }
  }

  &__profile {
    padding: 30px 0 0;
    height: 100%;
    position: relative;
  }

  &__logout-btn {
    width: 20px;
    padding: 2px;
    position: absolute;
    bottom: 20px;
    right: 0;
  }
}
