.menu {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  z-index: 5;
  transition: opacity 100ms ease-in-out;
  opacity: 0;
  display: flex;
  flex-direction: column;

  &-enter-done {
    opacity: 1;
  }

  &--slide {
    transform: translateY(-60px);
    transition: transform 200ms cubic-bezier(0.19, 1, 0.22, 1), opacity 100ms ease-in-out;

    &.menu {
      &-enter-done {
        transform: translateY(0);
      }
    }
  }

  &__header {
    padding: 20px 30px;
    min-height: 1em;
    display: flex;

    &-left {
      flex: 0 0 15px;
    }
    &-center {
      flex: 1;
      text-align: center;
    }
    &-right {
      flex: 0;
    }
  }

  &__content {
    padding: 10px 30px 30px;
    flex: 1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &-title {
      margin: 15px 0;
      outline: 0;
      font-size: 32px;
      line-height: 36px;
      height: 36px;

      &:placeholder-shown {
        color: #bdbabf;
      }
      &::-webkit-input-placeholder {
        color: #bdbabf;
      }
      &--empty {
        font-size: 18px;
      }
    }

    &-input {
      margin: 5px 0;
      outline: 0;
      font-size: 20px;
      line-height: 36px;
      height: 36px;

      &:placeholder-shown {
        color: #bdbabf;
      }
      &::-webkit-input-placeholder {
        color: #bdbabf;
      }
      &--empty {
        font-size: 18px;
      }
    }
  }

  &__footer {
    padding: 0 30px 20px;
    min-height: 1em;
    text-align: right;
    button {
      width: 18px;
    }
  }

  &__notice {
    display: block;
    text-align: center;
    background: $bgcolor;
    color: $subtxtcolor;
    padding: 15px;
    border-radius: 9px;
  }

  &__text-area {
    margin: 15px 0;

    &--empty {
      textarea {
        font-size: 18px !important;
      }
    }

    textarea {
      outline: 0;
      width: 100%;
      resize: none;
      font-size: 13px;
      line-height: 23px;
      font-weight: 300;
      color: $txtcolor;

      &:placeholder-shown {
        color: #bdbabf;
      }
      &::-webkit-input-placeholder {
        color: #bdbabf;
      }
    }
  }

  &__section {
    margin: 15px 0;
  }
  &__heading {
    margin: 30px 0 5px;
    color: $headingtxtcolor;
  }
  &__btn {
    color: $maincolor;
    font-weight: bold;
    &--disabled {
      color: $subtxtcolor;
    }
  }
}
