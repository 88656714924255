.login {
  padding: 30px;
  text-align: center;
  min-height: 100vh;
  background: white;

  input[type='text'],
  input[type='password'] {
    font-size: 19px;
  }

  &__title {
    margin: 100px auto 60px;
    text-align: right;
    width: 115px;

    p {
      font-size: 45px;
      font-weight: 600;
      line-height: 40px;
    }
    span {
      font-size: 9px;
      font-weight: 600;
      line-height: 9px;
    }
  }

  &__error {
    color: #f84e45;
    font-size: 11.5px;
    text-align: left;
    visibility: hidden;
    &--show {
      visibility: visible;
    }
  }

  &__submit {
    background: $maincolor;
    color: $mybubbletxtcolor;
    display: block;
    line-height: 45px;
    height: 45px;
    width: 100%;
    font-weight: 600;
    margin: 30px 0;
    &--disabled {
      background: #f6f5f7;
      color: #bdbabf;
    }

    &[disabled] {
      background: #f6f5f7;
      color: #bdbabf;
    }
  }
}
